/* .container { border: 5px solid cornflowerblue; }
.row { border: 5px solid red; }
.wrap {	border: 5px solid aqua; } */

html {
	position: relative;
	min-height: 100%;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	@include antialias;
	@include font-size($font-size-base);
	font-weight: $body-weight-normal;
	font-family: $font-family-body;
	line-height: $base-line-height;
	letter-spacing: 0.01em;
	word-spacing: -0.01em;
	-webkit-text-size-adjust: 100%;
	font-optical-sizing: auto;
	//background-color: $body-bg;
	background-image: url("/_/images/layout/wood.webp");
	background-attachment: fixed;
	color: $color-text;
	box-sizing: border-box;

	@include media-breakpoint-up(md) {
		position: relative;
		min-height: 100vh;
		padding-bottom: 132px;
		@include font-size($font-size-base + 1);
		.site_info {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 132px;
		}
	}
}

.alert {
	margin-bottom: 15px;
	background-color: $red;
	color: white;
	padding: 0 30px;
	text-align: center;
	@include font-size(15);
	line-height: 1.1;
	strong {
		display: block;
		margin-bottom: 5px;
		font-size: 110%;
		font-stretch: 75%;
	}
}


.block {
	padding: 10px;
}

.container {
	@include make-container();
	@include make-container-max-widths();
	&_fluid {
		@include make-container();
		@include make-container-max-widths();
		@include media-breakpoint-down(md) {
			max-width: none;
			padding: 0;
			.row { margin: 0; }
		}
	}
}

.row {
	@include make-row();
}

.wrap {
	@include make-col-ready();
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon-group {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	line-height: 1;
}

.icon {
	display: inline-block;
	line-height: 0;
	vertical-align: middle;
}

.icon-stars { fill: $color-review; }

picture {
	display: inline-block;
	max-width: 100%;
	width: auto;
	height: auto;
	border-style: none;
	line-height: 0;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

a {
	font-style: normal;
	text-decoration: underline;
	color: $color-link;
	&:hover,
	&:focus {
		color: $color-hover;
		text-decoration: none;
	}
	&.open:focus { outline: none; }
	&.phone {
		text-decoration: none;
		white-space: nowrap;
		cursor: default !important;
	}
	&.mask {
		text-decoration: none;
		color: inherit;
		&:hover { color: inherit; }
	}
}