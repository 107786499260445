.site_top {
	//padding-bottom: 20px;
	color: #EDE9DD;
	@include media-breakpoint-down(sm) {
		position: relative;
	}
}

.site_banner {
	line-height: 1;
	text-align: center;

	.row { align-items: center; }
	@include media-breakpoint-up(md) {
		//padding-bottom: 0;
		.wrap { @include make-col(1,3); }
		.banner_visit {
			order: 3;
			text-align: right;
		}
		.banner_branding {
			order: 2;
		}
		.banner_contact {
			order: 1;
			text-align: left;
		}
	}
}

.banner_button {
	text-align: right;
	@media (min-width: 340px) and (max-width: 767.98px) {
		position: absolute;
		z-index: 2;
		top: 10px;
		right: 10px;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.banner_contact {
	order: 3;
	@include media-breakpoint-only(sm) {
		@include make-col(1,2);
		text-align: left;
	}
}

	.hours-now {
		display: inline-block;
		line-height: 1;
		padding: 5px 0;
		cursor: pointer;
		.caret { fill: white; }
		&.hours_open {
			.caret { transform: rotate(-180deg); }
		}
	}

		.list-hours {
			display: none;
			margin: 15px auto;
			border-top: 1px solid darken($brand-primary-dark,15%);
			background-color: $brand-primary;
			@include list-unstyled;
			.date {
				display: inline-block;
				width: 110px;
				text-align: left;
			}
			li {
				padding: 5px 10px;
				&:nth-child(odd) {
					background-color: rgba(white,0.1);
				}
			}
			.open & {
				display: block;
			}
			@include media-breakpoint-up(sm) {
				position: absolute;
				margin: 0;
				left: 0;
				padding: 15px;
				z-index: 9;
				white-space: nowrap;
				background-color: $brand-primary-dark;
				li { padding: 10px; }
			}
		}

	.call-now {
		.phone {
			@include font-size(18);
			font-weight: $body-weight-bolder;
		}
		small {
			display: block;
			margin-top: 15px;
			@include font-size(16);
			font-stretch: 75%;
		}
		@include media-breakpoint-up(sm) {
			small { margin-top: 5px; }
		}
		@include media-breakpoint-up(md) {
			.phone {
				@include font-size(20);
			}
		}
	}

.banner_visit {
	order: 4;

	hr {
		margin: 10px 0;
		background-color: transparent;
	}

	@include media-breakpoint-only(sm) {
		@include make-col(1,2);
		text-align: right;
	}
}

/* .site_top {
	@include media-breakpoint-only(md) {
		.site_banner,
		.site_navigation {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.site_banner {
	padding: 0;
	font-family: $font-family-serif;
	text-align: center;
	background-color: $brand-primary-dark;
	color: white;
	.site_navigation {
		padding: 20px 15px;
	}

	@include media-breakpoint-up(md) {
		background-color: transparent;
		.banner_button { display: none; }
		.row { align-items: flex-start; }
		.banner_branding { 
			@include make-col(6); 
			order: 2; 
			padding: 20px 15px;
			z-index: 10;
			text-align: center;
		}
		.banner_contact { 
			@include make-col(5); 
			order: 1; 
		}
		.banner_visit { 
			@include make-col(5); 
			order: 3; 
		}
		.banner_visit, 
		.banner_contact { margin-top: 35px; }
		.banner_conversion { 
			@include make-col(16); 
			order: 4; 
			margin-top: -80px;
		}
	}
	@include media-breakpoint-up(lg) {
		.banner_conversion {
			margin-top: -75px;
		}
	}
}

	.banner_button,
	.banner_branding {
		align-self: stretch;
	}

.banner_button {
	@include make-col(1,4);
	padding-right: 15px;
	order: 2;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.banner_branding {
	@include make-col(3,4);
	order: 1;
	padding: 15px;
	text-align: left;
	line-height: 0;
	* { line-height: 0; }
}

	.logo {
		display: inline-block;
		max-width: 120px;
		@include media-breakpoint-up(md) {
			max-width: none;
			picture {
				width: 240px;
				height: 124px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-image: url("/_/images/layout/logo-bg.png");
				img {
					margin-top: -10px;
					width: 148px;
					height: 67px;
				}
			}
		}
	}

.banner_contact {
	order: 4;
	background-color: $gray-300;
	color: #333333;
	@include media-breakpoint-down(sm) {
		.map { order: 1; }
		.time { order: 3; }
		.call { order: 2; }
	}
	@include media-breakpoint-up(md) {
		background-color: transparent;
		text-align: left;
		color: white;
	}
}

.banner_visit {
	order: 5;
	background-color: white;
	color: #333333;
	@include media-breakpoint-up(md) {
		background-color: transparent;
		text-align: right;
		color: white;
	}
}

.banner-icon-group {
	svg { margin-right: 5px; }
	@include media-breakpoint-down(sm) {
		a {
			display: flex;
			padding: 15px 0;
			flex-wrap: nowrap;
			align-items: center;
			.time,
			.city { margin-left: auto; }
		}
	}
	@include media-breakpoint-up(sm) {
		@include font-size(17);
	}
	@include media-breakpoint-up(md) {
		@include font-size(15);
		line-height: 1.2;
		svg { display: none; }
		span { display: block; }
	}
	@include media-breakpoint-up(lg) {
		@include font-size(17);
	}
	@include media-breakpoint-up(xl) {
		@include font-size(18);
	}
}

.banner_conversion { 
	order: 3; 

	.btn {
		line-height: 1;
		&::before {
			display: block;
			@include font-size(16);
			line-height: 1;
		}
	}
	@include media-breakpoint-up(md) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: flex-end;
		justify-content: space-between;
		> div { 
			padding: 5px 15px;
			background: none; 
			+div { border-top: none; }	
			@include media-breakpoint-up(md) {
				a::before { content: none; }
			}
		}
		.btn {
			@include font-size(16);
			font-style: normal;
		}
	}
}


 */