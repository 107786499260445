small,
.small {
	font-size: $font-size-sm * 1px;
}

b, .b {
	font-weight: $body-weight-bold;
}

strong, .strong {
	font-weight: $body-weight-bolder;
}

em, i,
.em, .i {
	font-style: italic;
	//font-synthesis: none;
}