hr {
	display: block;
	width: 100%;
	height: 1px;
	margin: 30px auto;
	border: none;
	background-color: darken($body-bg, 20%);

	&.hr-inset {
		border: none;
		box-shadow: 0 1px 0 0 white;
	}

}