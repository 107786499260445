.glide {
	position: relative;
}

.glide__track {
	margin: 0;
}

.glide__arrow {
	transition: all 300ms;
	position: absolute;
	display: block;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	line-height: 0;
	padding: 10px 13px;
	border: none;
	border-radius: 30px;
	background-color: rgba(white,0.5);
	cursor: pointer;
	svg { fill: $brand-secondary; }
	&:hover { 
		transition: all 300ms;
		background-color: $brand-secondary !important; 
		svg { fill: white; transition: all 300ms; }
	}
	&--left {
		left: 15px;
		padding-right: 16px;
	}
	&--right {
		right: 15px;
		padding-left: 16px;
	}
	.reviewGlide & {
		background-color: #cccccc;
	}
}

.glide__slide {
	cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
	padding: 0;
}

.glide__bullets {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.glide__bullet {
	transition: background-color 300ms;
	display: inline-block;
    box-sizing: border-box;
	width: 19.5%;
	height: 30px;
	margin: 0;
	padding: 0;
	border: none;
	background-color: rgba(white,0.15);
	cursor: pointer;
	&:hover {
		transition: background-color 300ms;
		background-color: $brand-highlight;
	}
	&--active {
		transition: background-color 300ms;
		background-color: white !important;
	}
}   