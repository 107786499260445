.btn {
	@include btn(8px 12px, 4px);
	
	&-main {
		@include btn-solid($brand-primary-light, $brand-secondary, white, false, true);
	}

	&-primary {
		@include btn-solid($brand-primary-light, $brand-secondary, white, false, true);
	}

	&-secondary {
		@include btn-solid($brand-secondary-light, $brand-secondary-dark, white, false, true);
		&-ghost {
			@include btn-solid(transparent, $brand-secondary, white, false, true);
			border: 2px solid $brand-secondary;
			color: $brand-secondary;
		}
	}

	&-accent {
		@include btn-solid($brand-accent-light, $brand-secondary, white, false, true);
	}

	&-review {
		@include btn-solid($color-review-light, $brand-secondary, white, false, true);
	}

	&-success {
		@include btn-solid($color-success-light, $brand-secondary, white, false, true);
	}

	&-danger {
		@include btn-solid($color-danger-light, $brand-secondary, white, false, true);
	}

	&-info {
		@include btn-solid($color-info-light, $brand-secondary, white, false, true);
	}

	&-warning {
		@include btn-solid($color-warning-light, $brand-secondary, white, false, true);
	}

	&-sm {
		padding: 4px 8px;
	}

}
