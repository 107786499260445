@mixin btn($padding: 10px 20px, $radius: 2px) {

	display: inline-block;
	padding: $padding;
	border-radius: $radius;
	@include font-size(18);
	font-family: $font-family-sans-serif;
	font-weight: $body-weight-bold;
	font-stretch: 75%;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		cursor: pointer;
	}

}

	@mixin btn-solid($bg: $color-link, $to: $color-hover, $text: white, $shadow: false, $border: false) {
		background-color: $bg;
		background-image: linear-gradient(
			to bottom,
			$bg 50%,
			$to 50% 
		);
		background-repeat: no-repeat;
		background-size: 100% 225%;
		background-position: top left;
		color: $text;
		transition: all 300ms;

		@if $border == true { border: 1px solid lighten($bg,5%); }

		@if $shadow == true { text-shadow: 0 1px 2px rgba(black, 0.8); }

		&:hover,
		&:focus {
			transition: all 300ms;
			background-color: $bg;
			background-position: bottom left;
			color: $text;
			@if $border == true { border-color: lighten($to,5%); }
		}
	}
