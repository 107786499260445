p {
	margin: 0 0 $global_mb;
	&.lead { 
		@include font-size($font-size-lg);
		line-height: 1.4036;
	}
	@include media-breakpoint-up(md) {
		&.lead {
			@include font-size(20);
		}
	}
}