.mobile_toggle {
	//margin-bottom: 15px;
	transition: all 300ms;
	padding: 10px 12px;
	line-height: 0;
	border: none;
	border-radius: 4px;
	background-color: $brand-secondary; //$brand-primary;
	color: white;
	&:hover {
		transition: all 300ms;
		background-color: $brand-highlight;
		cursor: pointer;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}

	.icon-bars {
		fill: white;
	}



