.specials {
	&_front {
		margin-top: 30px;
		background-color: $brand-primary;
		color: white;
		text-align: center;
	}

	&_title {
		border-bottom: 1px solid white;
		margin-bottom: 15px;
		padding-bottom: 15px;
		h2 {
			color: white;
			font-family: $font-family-sans-serif;
			@include font-size(18);
			letter-spacing: 0.3em;
		}
		strong {
			display: block;
			@include font-size(32);
			letter-spacing: -0.04em;
			color: $brand-secondary;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			h2 { margin: 0 30px 0 0; }
			p { 
				margin: 0; 
				line-height: 1;
				font-size: 115%;
			}
		}
	}

	&_list {
		font-size: 85%;
		line-height: 1;
		letter-spacing: 0.05em;
		li {
			//background-color: $brand-secondary;
			padding: 10px 15px;
		}
		li:not(:last-child) { margin-bottom: 15px; }
		strong {
			color: $brand-secondary;
			display: block;
			margin: 0 0 5px;
			font-size: 120%;
			text-transform: uppercase;
			letter-spacing: -0.04em;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		@include media-breakpoint-only(md) {
			li { width: 50%; }
		}
		@include media-breakpoint-up(lg) {
			li {
				width: 33.3333%;
				&:nth-child(4),
				&:nth-child(5) {
					width: 45%;
				}
			}
		}
	}

}