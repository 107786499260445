.nav-title {
	margin: 0 0 25px;
	@include font-size(20);
	font-weight: $body-weight-bolder;
	line-height: 1;
	a {
		@include make-flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		margin-left: -15px;
		padding-left: 15px;
		color: white !important;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}

	.icon-close { fill: white; }

.mobile-toggle {
	display: inline-block;
	padding: 10px 12px;
	border-radius: 4px;
	background-color: $color-danger;
	line-height: 0;
}

.site_navigation {

	&.mobileShowing { display: block; }

	display: none;
	position: absolute;
	top: 0; left: 0;
	z-index: 8;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	padding: 10px;
	font-family: $font-family-sans-serif;
	
	font-stretch: 75%;
	text-transform: uppercase;
	text-shadow: 0 2px 4px rgba(black,0.5);

	text-align: left;
	background-color: #292420;

	.caret { fill: white; }

	.nav-item.active > .nav-link {
		text-shadow: none;
		background: white !important;
		color: $brand-secondary !important;
	}
	
	.nav-link {
		padding: 15px;
		font-weight: 600;
		color: white;
		&:hover { 
			background-color: $brand-secondary;
			cursor: pointer;
		}
	}
	
	@include media-breakpoint-down(sm) {
		.nav > .nav-item {
			&:not(:last-child) { border-bottom: 1px solid black; }
			&:not(:first-child) { border-top: 1px solid rgba(white,0.1); }	
		}

		.dropdown-nav {
			background-color: rgba(black,0.4);
			.nav-item:not(:last-child) {
				border-bottom: 2px solid $brand-primary-light;
			}
		}
	}

	@include media-breakpoint-up(md) {
		display: block;
		position: relative;
		top: unset; left: unset; right: unset;
		width: unset; height: unset;
		overflow: visible;
		padding-top: 0;
		background-color: transparent;

		.nav {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: stretch;
			justify-content: space-between;
			line-height: 0;
			position: relative;
		}

		.nav > .nav-item {
			width: 100%;

			&:not(:last-child) { margin-right: 1.5%; }
			
			&.active > .nav-link {
				background-color: transparent !important;
				border-color: transparent !important;
				color: white !important;
				text-shadow: none;
			}

			> .nav-link {
				@include btn(15px 15px, 4px);	
				@include btn-solid(rgba($brand-primary-dark, 0.5), $brand-secondary, white, false, true);
				border-color: rgba(white,0.2);
				text-align: center;
				white-space: nowrap;
				.caret { 
					transition: fill 300ms;
					fill: white; //$brand-secondary-light; 
				}
				
				&:hover, &:focus {
					.caret { 
						transition: fill 300ms;
						fill: white; 
					}
				}

			}

		}
		
		.nav-link {
			width: 100%;
			padding: 10px;
			line-height: 1;
			//color: white;
			&:hover, &:focus {
				color: white;
			}
		}

		.caret_push {
			fill: $brand-secondary;
		}

		.dropdown-nav {
			position: absolute;
			min-width: 240px;
			z-index: 10;
			margin-top: 10px;
			padding: 15px;
			text-align: left;
			border-radius: 4px;
			background-color: #191613;

			.nav-item:not(:last-child) { border-bottom: 1px solid black; }
			.nav-item:not(:first-child) { border-top: 1px solid rgba(white,0.2);}

			.nav-item.active .nav-link {
				color: white !important;
				background-color: transparent !important;
				.caret_push { margin-left: 0 !important; }
			}

			.nav-link {
				padding-left: 0;
				overflow: hidden;
				&:hover,
				&:focus {
					background-color: transparent;
					color: $brand-secondary;
					.caret_push { margin-left: 0; }
				} 
			}
		}

		.item_page_contact.dropdown.open {
			.dropdown-nav {
				right: 0; left: auto;
			}
		}

	}

}