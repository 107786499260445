.block {
	.wrap > :first-child { margin-top: 0; }
	.wrap > :last-child { margin-bottom: 0; }
}

.content_page {

	padding: 45px 30px;

	.container {
		padding: 45px 30px;
		background-color: $body-bg;
		border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
		border: solid 5px $body-bg;
		box-shadow: 0 45px 35px -35px black;
	}

	.site_info .container {
		padding: 0 15px;
		background-color: transparent;
		border-radius: none;
		border: none;
		box-shadow: none;
	}

	@include media-breakpoint-down(md) {
		.wrap+.wrap { margin-top: 30px; }
	}
	@include media-breakpoint-up(lg) {
		.main { @include make-col(10); }
		.sidebar { @include make-col(6); }
	}
}

.service_page_wrapper {
	@include media-breakpoint-down(md) {
		.sidebar { margin-top: 30px; }
	}
	@include media-breakpoint-up(lg) {
		.main { @include make-col(10); }
		.sidebar { @include make-col(6); }
	}
}