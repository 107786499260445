h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
	margin: 20px 0;
	font-family: $font-family-headings;
	font-weight: normal;
	word-spacing: 0;
	line-height: 1;
}

h1, .h1 {
	@include font-size(25);
	letter-spacing: 0.125em;
	text-transform: uppercase;
	@include media-breakpoint-up(md) {
		@include font-size(32);
		letter-spacing: 0.2em;
	}
}

h2, .h2 {
	@include font-size(22);
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $brand-secondary;
	@include media-breakpoint-up(md) {
		@include font-size(26);
		letter-spacing: 0.175em;
	}
}

h3, .h3 {
	@include font-size(19);
	font-family: $font-family-sans-serif;
    font-weight: $body-weight-bolder;
    font-stretch: 75%;
    letter-spacing: -0.03em;
	text-transform: uppercase;
    word-spacing: 0.075em;
	@include media-breakpoint-up(md) {
		@include font-size(22);
	}
}

h4, .h4 {
	margin: 0 0 15px;
	@include font-size($font-size-base + 3);
	font-weight: $body-weight-bold;
	font-stretch: 75%;;
	font-family: $font-family-body;
}

h5, .h5,
h6, .h6 {
	margin: 0 0 15px;
	@include font-size($font-size-base);
	font-weight: $body-weight-normal;
	font-family: $font-family-body;
}
