.front_jumbo {
	padding: 0 15px; 
	picture { width: 100%;}

	@include media-breakpoint-only(md) {
		.jumbo_slides { margin-bottom: 30px; }
	}

	@include media-breakpoint-up(md) {
		.jumbo_announce { order: 2; }
		.jumbo_slides { order: 1; }
	}

	@include media-breakpoint-up(lg) {
		.jumbo_announce { @include make-col(2,6); }
		.jumbo_slides { @include make-col(4,6); }
	}

	@include media-breakpoint-only(lg) {
		.glide,
		.glide__track,
		.glide__slides,
		.glide_slide {
			height: 100%;
		}
	}

}

	.jumbo_announce {

		.widget-wrap {
			border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
			color: $color-text;
			.widget { margin: 0; }
			+.widget-wrap { margin-top: 15px; }
			&.needs-bg {
				background-color: $body-bg;
			}
		}

		@include media-breakpoint-only(md) {
			.widget_wrapper {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.widget-wrap {
					width: 48%;
					+.widget-wrap { margin: 0 0 0 15px; }
				}
				.widget {
					height: 100%;
				}
			}
		}

	}

	.jumbo-slides {

		.glide__track {
			border-radius: 15px 225px 15px 255px/255px 15px 225px 15px;
		}

		@include media-breakpoint-up(md) {

			picture { 
				opacity: 0; 
			}

			.glide__slide {
				position: relative;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				line-height: 0;
			}

			.slide__1 { background-image: url("/_/images/slides/lg/1.webp"); }
			.slide__2 { background-image: url("/_/images/slides/lg/2.webp"); }
			.slide__3 { background-image: url("/_/images/slides/lg/3.webp"); }
			.slide__4 { background-image: url("/_/images/slides/lg/4.webp"); }
			.slide__5 { background-image: url("/_/images/slides/lg/5.webp"); }
			.slide__6 { background-image: url("/_/images/slides/lg/6.webp"); }
			.slide__7 { background-image: url("/_/images/slides/lg/7.webp"); }
			.slide__8 { background-image: url("/_/images/slides/lg/8.webp"); }
			.slide__9 { background-image: url("/_/images/slides/lg/9.webp"); }

		}

	}

	.no-webp .jumbo-slides {

		@include media-breakpoint-up(md) {
			.slide__1 { background-image: url("/_/images/slides/lg/1.jpg"); }
			.slide__2 { background-image: url("/_/images/slides/lg/2.jpg"); }
			.slide__3 { background-image: url("/_/images/slides/lg/3.jpg"); }
			.slide__4 { background-image: url("/_/images/slides/lg/4.jpg"); }
			.slide__5 { background-image: url("/_/images/slides/lg/5.jpg"); }
			.slide__6 { background-image: url("/_/images/slides/lg/6.jpg"); }
			.slide__7 { background-image: url("/_/images/slides/lg/7.jpg"); }
			.slide__8 { background-image: url("/_/images/slides/lg/8.jpg"); }
			.slide__9 { background-image: url("/_/images/slides/lg/9.jpg"); }
		}

	}